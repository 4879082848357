/* HK Gorkest */

@font-face {
  font-family: 'Piggment GT';
  src: url('../fonts/CircularStd-Book.ttf');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Piggment GT';
  src: url('../fonts/CircularStd-Medium.ttf');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Piggment GT';
  src: url('../fonts/CircularStd-Bold.ttf');
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Piggment GT';
  src: url('../fonts/CircularStd-Black.ttf');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}